<template>
    
    <router-view />

</template>

<script>
import EventService from '@/services/EventService.js';


export default {
  name: 'App',
  components: {
  },
  created() {

    //Get Query
      var qs = window.location.search.substring(1);
      

    //Check if token has value
      if (qs) {
        
        var qsvalue = JSON.parse('{"' + decodeURI(qs).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        
        //Get Incentive URL
        EventService.validateToken(qsvalue.token)
          .then( response => {
            
            console.log('Valid Token :', response.data)
            
            //Add the url to the data model
            var tokenValidation = response.data;

            if (tokenValidation == true) {
              // Valid Token
              
              // var dataUpdate = {};

              // dataUpdate.token = qsvalue.token;
              // dataUpdate.showbtn = true;

              this.$store.commit("updateData", {
                //Add the token to the data model
                token: qsvalue.token,
                // Activate the page
                showbtn: true
              });
              
              // stateUpdate.state = 'active';
              this.$store.commit("updateState", { state: 'active'});
            
            } else {
              // NotValid Token
              console.warn("NotValid Token")
              // this.state = 'error';
              this.$store.commit("updateState", { state: 'error'});

            }

          })
          .catch( error => {
            console.log(error);
            this.$store.commit("updateState", { state: 'error'});
            // this.state = 'error';
          });


      } else {
        console.error('Error, No QueryString');
        // this.state = 'error';
        this.$store.commit("updateState", { state: 'error'});
      }

  },
}
</script>

<style>
  body {
      margin: 0;
      padding: 0;

      background-color: #dddddd;

      display: flex;
      justify-content: center;
      align-items: center;

      min-height: 100vh;

      font: 16px/1.4 Helvetica, Arial, sans-serif;
      color: #53565a;
     
      width: 100%;
      min-width: 300px;
  }
  #app {
      width: 100%;
      min-width: 300px;

      margin: 0 auto;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

  }

  a {
    color: inherit;
  }
  a:hover {
    font-weight: bold;
  }



</style>
