import axios from 'axios'

const apiClient = axios.create({
  baseURL: '/app',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
    validateToken(token) {
      return apiClient.get('/app.php?token='+ token +'&type=valid');
    },
    
    claimGift(token) {
      return apiClient.get('/app.php?token='+ token +'&type=clicked');
    },

    getStats() {
      return apiClient.get('/stats.php');
    },

}